import { PENDING_CUSTOMER_APPROVAL, VOLVO, NL } from "./constants";

const config = {
  clientCode: VOLVO,
  clientId: 10004,
  lang: [NL],
  defaultLang: NL,
  uiSettings: {
    brandSelection: false,
    languageSwitch: false,
    toolsMenu: true,
    downloadsMenu: false,
    showPrices: true,
    showCustomerApproval: false,
    approvalFilter: PENDING_CUSTOMER_APPROVAL,
    showAvailableCycles: true,
    showMigrationLink: false,
    sidebarFAQ: null,
    showFooterText: true,
    maintenanceMessage: null,
    showCalendar: false,
    showSuppliers: false,
    showRequestAccountButton: true,
    showSidebarAL: false,
    showLogos: false,
    showSignatures: false,
    weekendParticipationDate: false,
    participationDateDelay: 1,
    showParticipationDate: true,
  },
  downloadsPageReport: {
    reportId: null,
    viewId: null,
  },
};

export default config;
